export const appList = [
  {
    name: 'Splashy Dashboard',
    url: process.env.REACT_APP_SPLASHY_URL
  },
  {
    name: 'Water Area Calculator',
    url: process.env.REACT_APP_WATER_AREA_CALCULATOR_URL
  },
  {
    name: 'History',
    url: process.env.REACT_APP_HISTORY_URL
  },
  {
    name: 'BAIT',
    url: process.env.REACT_APP_BAIT_URL
  },
  {
    name: 'HERMES',
    url: process.env.REACT_APP_HERMES_URL
  },
  {
    name: 'LAVA',
    url: process.env.REACT_APP_LAVA_URL
  },
  {
    name: 'Duplicate Finder',
    url: process.env.REACT_APP_DUPLICATE_FINDER_URL
  },
  {
    name: 'MAUI',
    url: process.env.REACT_APP_MAUI_URL
  },
  {
    name: 'HSOC Logger',
    url: process.env.REACT_APP_HSOC_LOG_URL
  }
];
